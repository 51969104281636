import React from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import CodeEditor from 'elements/Code/CodeEditor'

import {
  FormHelperText,
  FormErrorMessage,
  FormControl,
  Stack,
  FormLabel,
  Input
} from '@chakra-ui/core'

export default function ViewEditorForm ({ values, onSubmit }) {
  // eslint-disable-next-line no-unused-vars
  const { handleSubmit, control, errors, register, formState } = useForm({
    defaultValues: values
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack maxWidth={600} spacing={10}>

        <FormControl isInvalid={!!errors.label}>
          <FormLabel htmlFor="label">Module name</FormLabel>
          <Input
            name="label"
            aria-describedby="label-helper-text"
            ref={register({ required: 'required field' })}
          />

          <FormErrorMessage>
            {errors.label && errors.label.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.summary}>
          <FormLabel htmlFor="summary">Module summary</FormLabel>
          <Input
            name="summary"
            aria-describedby="summary-helper-text"
            ref={register({ required: false })}
          />
          <FormHelperText id="summary-helper-text">
        Optional short summary (max 100 chars)
          </FormHelperText>
          <FormErrorMessage>
            {errors.summary && errors.summary.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.repository}>
          <FormLabel htmlFor="repository">Module repository</FormLabel>
          <Input
            name="repository"
            aria-describedby="repository-helper-text"
            ref={register({ required: false })}
          />
          <FormHelperText id="repository-helper-text">
        URl to source code file (leave blank if using direct source code editor)
          </FormHelperText>
          <FormErrorMessage>
            {errors.repository && errors.repository.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.source}>
          <FormLabel htmlFor="source">Source code</FormLabel>
          <Controller
            name="source"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={props => <CodeEditor
              initialValue="foo"
              name="source"
              aria-describedby="source-helper-text"
              height={200}
              {...props}
            />} // props contains: onChange, onBlur and value
          />

          <FormHelperText id="source-helper-text">
        Source code (NodeJS 12.x)
          </FormHelperText>
          <FormErrorMessage>
            {errors.source && errors.source.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>

      </Stack>
    </Form>
  )
}

ViewEditorForm.defaultProps = {
  values: {}
}
ViewEditorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object
}
